.applicant-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  padding: 1rem;
}

.applicant-container h2,
.applicant-container h3,
.applicant-container h4 {
  text-align: center;
}

.applicant-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-evenly;
  align-content: space-evenly;
  gap: 1rem;
}

.applicant-spec h4 {
  text-align: left !important;
}
.applicant-detail {
}
.confirm-back-button img {
  width: 50px;
  height: 25px;
}

.applicant-back-button:hover {
  background-color: #2c535767;
  border-color: #2c535767;
  transition: ease-in 0.2s;
}

.applicant-card {
  width: 40%;
  padding: 3rem;
  background-color: rgba(245, 245, 245, 0.342);
  border-radius: 1rem;
  background-color: #b8336d2a !important;
  box-shadow: 0 0 2px #b8336d59, 0 0 10px #b8336d59, 0 0 50px #b8336d59,
    0 0 70px #b8336d59;
}

.applicant-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.applicant-decline_btn {
  background-color: #757272 !important;
}
.applicant-decline_btn::after {
  border: 1px solid #757272 !important;
}

.applicant-btns a {
  background-color: #562361;
  color: white;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 1rem 1.5rem;
  position: relative;
  z-index: 2;
  border-radius: 10px;
  cursor: pointer;
}

.applicant-btns a::after {
  width: 90%;
  height: 90%;
  background-color: transparent;
  border: 1px solid #562361;
  content: "";
  transition: all 0.2s;
  z-index: -1;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 10px;
}

.applicant-btns a:hover::after {
  width: 100%;
  height: 100%;
  top: 4px;
  right: -6px;
}
