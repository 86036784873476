.blogHero-image {
  width: 250px;
  height: 500px;
  min-height: 250px;
  /* background-position: 50% 47%; */
  object-position: 50% 10%;
  object-fit: cover;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;
}

.blogHero-wrap {
  display: grid;
  grid-template-columns: auto;
  column-gap: 2rem;
  /* width: 80%; */
  justify-content: center;
  align-items: center;
  text-align: center;
}

.blogHero-header {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: #343a40;
}
.carousel-container {
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.blogHero_carousel-wrap {
  text-align: center;
  flex: 0 0 50%;
  max-width: 50%;
}

.carousel-slide {
}

.blogHero-posts {
  /* display: grid;
  grid-template-columns: auto auto auto; */
  display: flex;
  justify-content: center;
}

/* .carousel-slide img {
  width: 40% !important;
  min-height: 250px !important;
  background-position: 50% 50% !important;
  display: block;
} */
.blogHero-element {
  align-items: center;
  padding: 1rem;
}

.blogHero-desc {
  /* padding: 1rem; */
  /* width: 90%; */
  padding-bottom: 1rem;
  text-align: center;
}

.blogHero-desc h6 {
  font-size: 1rem;
}

.blogHero-desc a {
  background-color: #562361 !important;
  color: white;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 1rem 1.5rem;
  position: relative;
  z-index: 2;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
}

.blogHero-desc a:after {
  width: 90%;
  height: 90%;
  background-color: transparent;
  border: 1px solid #562361;
  content: "";
  transition: all 0.2s;
  z-index: -1;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 10px;
}

.blogHero-desc a:hover::after {
  width: 100%;
  height: 100%;
  top: 4px;
  right: -6px;
}

@media (max-width: 900px) {
  .blogHero-posts {
    margin: auto;
    width: 95%;
    overflow-x: hidden;
  }
  .blogHero-header {
    margin: auto;
  }
  .blogHero_carousel-wrap {
    max-width: 100%;
  }
  .blogHero-image {
    height: 250px;
  }
}

@media (max-width: 800px) {
  .blogHero-posts {
    margin: auto;
    width: 100%;
    overflow-x: hidden;
  }
  .blogHero-header {
    margin: auto;
  }
  .blogHero_carousel-wrap {
    max-width: 100%;
  }
  .blogHero-image {
    height: 475px;
  }
}
