.contactMe-logo {
  margin-top: 30px;
  width: 300px;
}

.contactMe_wall {
  margin-top: 5.5rem;

  height: 100%;
  width: 100%;
  z-index: -1;
  position: absolute;
}

.contactMe-logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactMe__Title {
  color: #bb3b71;
  font-size: 80px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  /* text-transform: uppercase; */
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  padding: 25px;
  margin-top: 1rem;
}

.contactMe-submit_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container1 input[type="text"],
.container1 input[type="email"],
.container1 input[type="number"],
.container1 textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

label[for="name"],
label[for="email"],
label[for="number"],
label[for="message"] {
  color: rgb(97, 80, 92);
}

text[for="name"],
text[for="email"],
text[for="number"],
text[for="desc"] {
  font-size: 20px;
  color: white;
}

textarea {
  height: 150px;
}

.container1 {
  border-radius: 1rem;
  background-color: #5c1936c9 !important;
  box-shadow: 0 0 2px #b8336d59, 0 0 10px #b8336d59, 0 0 50px #b8336d59,
    0 0 70px #b8336d59;
  padding: 20px;
  margin-left: 9.5rem;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: fit-content;
  min-width: 100%;
  height: 535px;
  width: 800px;
}

.container2 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 10px;
  background-color: #411d2e;
  padding: 30px;
  border: #8d2b5a;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: fit-content;
  min-width: 50%;
  height: 515px;
  width: 800px;
}

.container2 div {
  display: flex;
  flex-direction: column;

  gap: 0.5rem;
}

.contactMe_row {
  display: flex;
  width: 80%;
  /* padding-left: 250px; */
}

.column1 {
  width: 50%;
  height: 100px;
  padding-left: 50px;
}

.column2 {
  width: 95%;
  max-width: fit-content;
  min-width: fit-content;
}

@media screen and (max-width: 840px) {
  .column1 {
    padding-left: 0;
    margin-left: auto;
  }
  .column2 {
    margin-right: auto;
  }

  .contactMe_row {
    padding-left: 0;
  }
}

@media screen and (max-width: 640px) {
  .container1 {
    margin-left: auto;
    margin-right: auto;
  }

  .container2 {
    display: none !important;
    font-size: 1px;
    height: 500px;
  }

  .column1 {
    padding-left: 20px;
    max-width: fit-content;
    width: 90%;
    height: 100%;
  }

  textarea {
    height: 110px;
  }
}

@media screen and (max-width: 320px) {
  .contactMe__Title {
    font-size: 4rem;
  }
}
