.layout-container {
  margin: auto;
  width: 100%;
  /* border: 3px solid green; */
  padding: 10px;
}

/* -------------------------------- Navbar CSS --------------------------------- */
.header {
  display: grid;
  width: 100%;
  /* margin: auto; */
  grid-template-columns: auto auto;
  height: 75px;
  /* padding: 0.5rem; */
  border-top: 0.3rem solid #562361;
  box-shadow: inset 0px 0px 0px #f7d3ed, inset 0px -5px 5px #f5b1e262;
}

.header a {
  text-decoration: none;
  color: #b8336c;
  /* font-weight: 900; */
  font-size: 1rem;
  font-weight: 600;
  margin: 5rem;
  position: relative;
  transition: all 0.2s;
  text-transform: uppercase;
}
.header-image {
  padding-top: 2px;
  width: 175px;
  float: left;
  margin-left: 2rem;
  background-color: rgba(255, 255, 255, 0);
  transition: all 0.3s;
}

.header-image:hover {
  /* box-shadow: 0 0px 30px #b8336c; */
  width: 190px;
  transition: all 0.3s;

  cursor: pointer;
}

.header-links {
  height: 100%;
}

.nav-item:hover {
  height: 50px;
  transition: background-color 0.5s ease;
  /* background-color: var(--dark-color); */
  color: var(--mid-color);
}

.header button {
  margin-bottom: -5px;
  height: 25px;
  background-color: var(--brand-color);
  color: white;
  border-color: var(--brand-color);
  cursor: pointer;
  border-radius: 10px;
}

.nav-btn {
  display: flex;
  text-decoration: none;
  justify-content: center;
  padding: 0.5rem 1rem;
  height: 100%;
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;
  text-decoration: none;
  top: -5px;
  background-color: #b8336c !important;
  border: 5px #b8336c solid !important;
  font-size: 1rem;
  font-weight: 600;
  transition: all 0.2s;
  text-transform: uppercase;
  z-index: 1;
}

.nav-btn:hover {
  background-color: #b8336c;
  border: 5px #b8336c solid;
}

/* Displays the links horizontally */
.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  justify-content: end;
  right: 0;
  height: 40%;
}

.nav-item {
  position: relative;
  height: 100px;
  border-bottom: 2px solid transparent;
  display: flex;
  align-items: center;
  text-decoration: none;
  top: -15px;
  transition: all 0.3s;
}

.nav-item:hover {
  border-bottom: 8px solid #b8336c;
  box-shadow: 0 0 0px var(--brand-color);
  /* box-shadow: 0 0 5px var(--brand-color), 0 0 25px var(--brand-color); */
  transition: all 0.3s;
  /* padding-bottom: 2rem; */
  padding: 1rem;
  cursor: pointer;
}
.nav-item:hover a {
  /* position: absolute; */
  text-shadow: 1px 0 0;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.fa-bars {
  /* color: #fff; */
}

.menu-icon {
  display: none;
}
@media (max-width: 900px) {
  .layout-wrap {
    width: auto;
    /* width: -webkit-fill-available; */
  }
  .header {
    overflow: hidden;
    width: auto;
  }
  .header a {
    font-size: 1rem;
    font-weight: 600;
    margin: 3rem;
    text-transform: uppercase;
  }
  .header-image {
    width: 150px;
    padding-top: 0rem;
  }
  .header-image:hover {
    /* box-shadow: 0 0px 30px #b8336c; */
    width: 150px;
    transition: all 0.3s;
    cursor: pointer;
  }
}
@media (max-width: 600px) {
  .header-image {
    width: 150px;
    /* padding-top: 0rem; */
  }
  .header-image:hover {
    width: 150px;
    transition: all 0.3s;
    cursor: pointer;
  }
  .navbar.active {
    background: var(--dark-color);
    overflow: hidden;
  }

  @keyframes show {
    100% {
      opacity: 1;
      transform: none;
    }
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: calc(100vh - 77px);
    position: fixed;
    top: 60px;
    opacity: 1;
    transition: all 0.2s ease;
    display: none;
    z-index: 2;
  }

  .nav-menu.active {
    background: #562361fb;
    height: 100%;
    right: 0;
    opacity: 1;
    transition: all 0.2s ease;
    z-index: 2;
    visibility: visible;
    display: block;
    font-weight: bolder;
    font-size: larger;
    z-index: 1;
    transition: cubic-bezier(0.755, 0.05, 0.855, 0.06) 0.3;
  }

  .menu-icon.active {
    position: fixed;
  }

  .no-scroll {
    overflow-y: hidden;
    position: fixed;
    /* touch-action: none; */
  }

  .nav-links {
    flex: 1;
    justify-content: start;
    padding: 2rem;
    width: 100%;
    display: none;
  }

  .nav-links.active {
    flex: 1;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: white;
    font-weight: bolder;
    /* color: var(--brand-color); */

    margin: auto;
  }

  .nav-links:hover {
    color: #f00946;
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  .nav-item:hover {
    border: none;
  }

  .nav-item {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 90%;
    margin: auto;
    /* height: 20px; */
    background-color: var(--brand-color);
    border: 3px solid var(--mid-color);
    border-radius: 10px;
    margin: 1rem;
    margin-top: 2rem;
    top: 0px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-btn {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    /* max-width: 80%; */
    height: 120px;
    margin: auto;
  }

  .header button {
    margin-bottom: -5px;
    height: 40px;
    font-weight: bolder;
    font-size: large;
    background-color: var(--brand-color);
    color: white;
    border-color: var(--brand-color);
    cursor: pointer;
  }
}

@media (max-width: 700px) {
  .header-image {
    width: 150px;
    height: 50px;
    padding: 10px;
  }
  .header-image:hover {
    /* box-shadow: 0 0px 30px #b8336c; */
    width: 150px;
    transition: all 0.3s;

    cursor: pointer;
  }
}

/* ----------------------------------------------------------------------- */
/* @media (max-width: 500px) {
  main {
    width: auto;
  }
}
@media (max-width: 700px) {
  main {
    width: fit-content;
  }
} */
/* ------------------------------------- Footer CSS ---------------------------------- */
footer {
  background-color: #111111;
  color: white;
  display: grid;
  grid-template-columns: auto;
}

.footer-cat_header {
  color: #575757;
}

.slaymaster-logo-bottom-container {
  display: flex;
  justify-content: center;
  margin: auto;
  width: 85%;
  /* border: 3px solid green; */
  padding: 10px;
  /* border-bottom-style: solid;
  border-bottom-color: rgb(221, 218, 218);
  margin-bottom: 2rem; */
}

.slaymaster-logo-bottom {
  width: 95%;
  margin-bottom: 3rem;
  transition: all 0.2s;
}

.slaymaster-logo-bottom:hover {
  width: 100%;
  transition: all 0.2s;
}

.footer-links {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: auto;
  gap: 200px;
}

.footer-cat_links {
  /* cursor: pointer; */
  list-style: none;
  padding-bottom: 15px;
}

.footer-links a {
  text-decoration: none !important;
  color: var(--light-color);
  list-style: none;
  padding-bottom: 15px;
}

.footer-links a:visited {
  text-decoration: none !important;
  color: var(--light-color);
}

.footer-links a:hover {
  /* text-decoration: underline !important; */
  color: var(--brand-color);
}

.footer-info {
  display: grid;
  grid-template-columns: auto auto auto;
  margin: auto;
  width: 85%;
  /* border: 3px solid green; */
  padding: 10px;
}

.footer-copyright {
  /* display: flex; */
  margin-top: auto;
  margin-bottom: auto;
  color: #575757;
}

.footer-copyright a {
  color: #575757;
  text-decoration: none;
}

.footer-copyright a:hover {
  color: var(--brand-color);
  text-decoration: line;
  text-decoration: underline;
}

.footer-signature {
  text-align: center;
  margin-left: -120px;
  display: grid;
  width: 45%;
  margin: auto;
  grid-template-columns: auto auto;
  grid-column-gap: -150px;
}

.footer-signature-logo {
  width: 100px;
}

.footer-icon-group ul {
  display: flex;
  float: right;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: #575757;
}

.footer-icon-group li {
  padding: 10px;
}

.footer-icon-group a {
  text-decoration: none;
  color: var(--mid-color);
}

.footer-icon-group_social svg {
  width: 25px;
  height: 35px;
  padding: 5px;
  padding-bottom: 25px;
  transition: all 0.2s;
}
.footer-icon-group_social svg:hover {
  width: 35px;
  height: 35px;
  transition: all 0.2s;
  padding: 5px;
  padding-bottom: 25px;
  color: #b8336c;
}
@media (max-width: 980px) {
  .footer-info {
    font-size: 0.8em;
  }
  .footer-icon-group li {
    padding: 10px;
  }
  .slaymaster-logo-bottom-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .slaymaster-logo-bottom {
    width: 95%;
    margin-bottom: 0rem;
  }
  .footer-cat_header {
    text-align: center;
  }
  .footer-icon-group_social svg {
    width: 30px;
    height: 30px;
  }
  .footer-icon-group_social svg:hover {
    width: 30px;
    height: 30px;
    transition: all 0.2s;
    padding: 0rem;
    color: #b8336c;
  }
  .footer-links {
    gap: 1rem;
  }
}
@media (max-width: 650px) {
  .footer-info {
    font-size: 0.7em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-aboutUs,
  .footer-links_services {
    margin-right: auto;
    margin-left: 4rem;
  }

  .footer-icon-group li {
    padding: 8px;
  }

  .footer-icon-group ul {
    float: none;
  }
  .footer-links {
    grid-template-columns: auto;
    gap: 0px;
  }
}
/* ----------------------------------------------------------------------- */
