.jumbo-wrap {
  background-color: #e7e9ef;
}
.heroImage {
  width: 100%;
  /* max-width: 5000px; */
  height: 100%;
}
figure {
  margin: 0;
}

.justify-content-end {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
}

.row {
  flex-direction: column;
  justify-content: flex-end !important;
  width: 75%;
  /* width: 50rem; */
}

.heroImage {
  box-shadow: 0 0 0.5rem 0 rgb(17 17 17 / 20%);
}

figcaption {
  color: #562361 !important;
  cursor: default;
  font-weight: 600;
  min-width: 200px;
  left: 0;
  letter-spacing: 0.05rem;
  line-height: 1;
  padding: 2rem;
  position: absolute;
  text-shadow: 0 0.02rem 0 rgb(17 17 17 / 50%);
  /* width: 100%; */
}

figcaption {
  display: flex;
  font-size: 1.5rem;
  top: 7rem;
  padding-left: 18rem;
  width: 70%;
  justify-content: flex-end !important;
}

figcaption h2 {
  font-size: 3rem;
}
figcaption p {
  /* font-size: 3rem; */
}

.booking-wrap {
  background-color: #fcfcfc;
}
.booking-select_wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.75fr 0.75fr 1fr;
  padding: 1rem;
  margin-left: 5rem;
  margin-right: 5rem;
}

.booking-field,
.booking-input-field .booking-field_input,
.booking-field_select,
.booking-field_button {
  background-color: #fcfcfc;
  border: 1px solid lightgrey;
  color: inherit;
  line-height: 22px;
  font-size: 15px;
  /* padding: 10px; */
  transition: all 0.2s;
}
.booking-input-field {
  border: 2px solid lightgrey;
}

.booking-field {
  /* padding: 1px; */
}

.booking-field select {
  width: 100%;
  height: 100%;
}

.booking-input-field input {
  width: 92.5%;
  height: 53%;
}

.booking-field button {
  width: 100%;
  height: 100%;
  background-color: #562361;
  color: #fcfcfc;
  font-weight: 900;
  font-size: largeR;
  text-transform: capitalize;
  cursor: pointer;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.booking-field button:hover {
  background-color: var(--brand-color);
}

@media (max-width: 930px) {
  figcaption {
    width: -webkit-fill-available;
    top: 3rem;
    left: -2rem;
  }
  .booking-select_wrap {
    width: 90%;
    margin-left: 2rem;
  }
  .booking-field:last-of-type {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
@media (max-width: 800px) {
  figcaption {
    color: #562361 !important;
    cursor: default;
    font-size: 1.4rem;
    font-weight: 600;
    min-width: 200px;
    left: 14rem;
    letter-spacing: 0.05rem;
    line-height: 1;
    padding: 0rem;
    position: absolute;
    text-shadow: 0 0.02rem 0 rgb(17 17 17 / 50%);
    /* width: 100%; */
  }
}

@media (max-width: 700px) {
  .heroImage {
    width: 100%;
    /* max-width: 5000px; */
    /* height: 120%; */
    height: 13rem;
    box-shadow: 0 0 0;
  }
  figcaption {
    color: #562361 !important;
    cursor: default;
    font-weight: 600;
    min-width: 200px;
    left: 15px;
    letter-spacing: 0.05rem;
    line-height: 1.2;
    padding: 0rem;
    position: absolute;
    text-shadow: 0 0.02rem 0 rgb(17 17 17 / 50%);
    /* width: 100%; */
  }
  .row {
    text-align: center;
    width: 70%;
    /* width: 50rem; */
  }
  .booking-input-field {
    display: table;
    border: 2px solid lightgrey;
    /* line-height: 22px; */
  }
  .booking-input-field input {
    display: block;
    width: 94.5%;
    height: 90%;
    border-radius: 0px;
    /* padding: 5px !important; */
    /* font-size: 15px !important; */
    /* margin-bottom: 2px !important; */
  }
  .booking-input-field input[type="date"]:before,
  .booking-input-field input[type="time"]:before {
    content: attr(placeholder) !important;
    color: black;
    /* width: -webkit-fill-available; */
    text-align: center;
    /* margin-right: 0.5em; */
  }
  .booking-input-field input[type="time"]::content {
    content: attr(placeholder) !important;
    color: black;
    width: auto;
    /* margin-right: 0.5em; */
  }
  .booking-input-field input[type="date"]:focus:before,
  .booking-input-field input[type="date"]:valid:before,
  .booking-input-field input[type="time"]:focus:before,
  .booking-input-field input[type="time"]:valid:before {
    content: "";
  }
  .booking-field select {
    /* width: 96%; */
    height: 100%;
  }
  .booking-select_wrap {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr;
    height: 100%;
    padding: 1rem;
    margin-left: 0rem;
    margin-right: 0rem;
    margin: auto;
    /* gap: 1px; */
  }
  figcaption {
    display: flex;
    font-size: 15px;
    top: 4.5rem;
    padding-left: 1.5rem;
    width: 90%;
    justify-content: flex-end !important;
  }

  figcaption h2 {
    font-size: 1.5rem;
  }
  figcaption p {
    text-align: start;
    padding-left: 2rem;
  }

  .booking-field button {
    width: 100%;
    height: 100%;
    background-color: #562361;
    color: #fcfcfc;
    font-weight: 900;
    font-size: largeR;
    text-transform: capitalize;
    cursor: pointer;
    border-radius: 10px;
  }
}

@media (max-height: 500px), (max-width: 900px) {
  .jumbo-wrap {
    /* width: 100%; */
  }
}
