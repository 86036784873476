.register-layout {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.register-form-container {
  align-items: stretch;
  text-align: left;
  padding: 2rem;
  width: 50%;
}

.register-header {
  text-align: center;
}
.register-form {
  display: flex;
  flex-direction: column;
}

.register-names,
.register-contact {
  display: grid;
  grid-template-columns: auto auto;
  gap: 5rem;
}

.register-form label {
}
.register-form input {
  width: 100%;
  margin-bottom: 2rem;
  border-color: #b8336c;
  box-shadow: 0 0 2px #b8336d59, 0 0 10px #b8336d59, 0 0 10px #b8336d59,
    0 0 70px #b8336d59;
}

.register-login_link {
  text-decoration: underline;
  color: #db2a77;
}

.register-button-container {
  height: 5rem;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.register-button-container button {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: #562361 !important;
  color: white;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 1rem 1.5rem;
  position: relative;
  z-index: 2;
  border-radius: 10px;
  cursor: pointer;
}

.register-button-container button::after {
  /* width: 90%;
    height: 90%; */
  background-color: transparent;
  border: 1px solid #562361;
  content: "";
  transition: all 0.2s;
  z-index: -1;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 10px;
}

.register-button-container button:hover::after {
  width: 100%;
  height: 100%;
  top: 4px;
  right: -6px;
}
