.termOfSale-page_header {
  text-align: center;
  padding: 1rem;
}
.termOfSale-wrap {
  padding: 1rem 0rem 5rem 0rem;
}
.termOfSale-wrap p {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.75;
}
.termOfSale-wrap h1 {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.25;
}
.termOfSale-wrap h3 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
}
.termOfSale-page-container {
  padding: 2rem 5rem 2rem 5rem;
  margin: 2rem 10rem 0rem 10rem;
  /* background-color: rgb(250, 250, 250); */
  background-color: #e1cbd81a;
  border-radius: 5rem;
}

@media (max-width: 800px) {
  .termOfSale-page-container {
    padding: 2rem 1rem 2rem 1rem;
    margin: 0rem 1rem 0rem 1rem;
    border-radius: 5rem;
  }
  .termOfSale-wrap p {
    font-size: 1rem;
  }
  .termOfSale-wrap h3 {
    font-size: 1.3rem;
  }
}
