/* * {
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
.my-form {
  width: 100%;
  height: 100%;
} */

.my-form {
  height: 100%;
  padding-bottom: 20rem;
}
