.aboutUs-header {
  height: 350px;
  display: flex;
  align-items: center;
  padding: 2rem;
  padding-left: 20rem;
  background-color: #e1cbd82a;
}
.aboutUs-header_container {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
}
.header_content h1 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.25;
}
.header_content p {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.75;
}
.header_image img {
  width: 70%;
}
.aboutUs-mission,
.aboutUs-story {
  display: grid;
  grid-template-columns: auto auto;
  width: 1150px;

  gap: 1rem;
}
.aboutUs-section {
  height: 400px;
  display: flex;
  align-items: center;
  padding: 3rem 9rem 3rem 21rem;
}
.story_image,
.mission_image {
  width: 100%;
}
.story_image img,
.mission_image img {
  width: 100%;
}
.mission_content,
.story_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 1rem; */
  margin: 0;
}
.mission_content h2,
.story_content h2 {
  width: 75%;
  line-height: 1;
}
.mission_content p,
.story_content p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75;
}

.articles-wrap h1 {
  text-align: center;
}
.articles-container {
  background-color: #faf1f575;
  display: grid;
  grid-template-columns: auto auto;
  /* background-image: url("../../public/images/walls/purple.jpg"); */
  padding: 3rem;
  padding-top: 1rem;
  box-shadow: inset 0px 5px 5px #f7d3ed, inset 0px -5px 5px #f5b1e2;
}

.articles-container a {
  text-decoration: none;
}

.article-card_info h2 {
  color: #922858;
}

.article-card_info p {
  color: black;
  font-weight: 600;
}

.article-card a {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-color: #b8336c !important;
  border: 2px solid;
  margin: 5rem;
  background-color: #eed8e8;
  column-gap: 2rem;
  padding: 10px;
}
.article-card a:hover {
  border: 2.5px solid;
  box-shadow: 0 4px 30px #b8336c;
  transition: all 0.3s;
}

.article-card_image img {
  float: left;
  width: 100%;
  height: 150px;
  object-fit: contain;
  padding: 1rem;
}

.pagination button {
  background-color: #cc2a70d0;
  border-color: #685568;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  color: #f3e6ec;
}

.pagination button:hover {
  /* border: 2.5px solid; */
  box-shadow: 0 4px 30px #b8336c;
  transition: all 0.3s;
}

.pagination {
  display: flex;
  justify-content: center;
}

@media (max-width: 700px) {
  .aboutUs-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 0rem 20rem 1rem;
  }
  .header_image img {
    width: 95%;
  }
  .header_content h1 {
    text-align: center;
  }
  .aboutUs-mission,
  .aboutUs-story {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    gap: 0rem;
    padding: 5px 0rem 0rem 0rem;
  }
  .aboutUs-section {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;
  }
  .aboutUs-header_container {
    grid-template-columns: auto;
    gap: 0rem;
  }
  .articles-container {
    grid-template-columns: auto;
    /* background-image: url("../../public/images/walls/purple.jpg"); */
    padding: 0px;
    padding-top: 1rem;
  }
  .article-card a {
    display: grid;
    grid-template-columns: auto;
    border: 2px solid;
    margin: 2rem;
    column-gap: 0rem;
    padding: 10px;
  }
  .article-card_image img {
    width: 90%;
  }
  .mission_content,
  .story_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    margin: 0;
  }
  .mission_content h2,
  .story_content h2 {
    font-size: 2rem;
  }
  .pagination {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    align-content: center;
  }
  .pagination button {
    padding: 5px;
    margin: 10px;
  }
  .pagination button.active {
    box-shadow: 0 4px 30px #b8336c;
    transition: all 0.3s;
  }
}
