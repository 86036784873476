:root {
  --brand-color: #a80379;
  --dark-color: #1e293b;
  --mid-color: #cbd5e1;
  --light-color: #f8fafc;
  --hover-color: #fc39c5;
  --other-color: #562361;
}

html {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
}

@media (max-width: 700px) {
  :root {
    width: fit-content;
  }
}
@media (max-width: 500px) {
  :root {
    width: auto !important;
  }
}

@font-face {
  font-family: MyFont;
  src: url(../public/beautylynk-gothic/CenturyGothicStd.otf) format("truetype");
  font-weight: 100 500;
  font-style: normal;
}
@font-face {
  font-family: MyFont;
  src: url(../public/beautylynk-gothic/CentGothWGL-Italic.ttf)
    format("truetype");
  font-weight: 100 500;
  font-style: italic;
}
@font-face {
  font-family: MyFont;
  src: url(../public/beautylynk-gothic/CenturyGothicStd-Bold.otf)
    format("truetype");
  font-weight: 600 1000;
  font-style: normal;
}
@font-face {
  font-family: MyFont;
  src: url(../public/beautylynk-gothic/CenturyGothicStd-BoldItalic.otf)
    format("truetype");
  font-weight: 600 1000;
  font-style: italic;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: MyFont, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
