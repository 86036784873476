.book-now-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.form-wrap {
  align-items: stretch;
  text-align: left;
  padding: 4rem;
  width: 80%;
}

/* .header {
  position: sticky;
  top: 0;
  background-color: #ffffffe0;
  z-index: 2;
} */

.book-now-field,
.book-now-field_dets {
  transition-duration: 0.5s;
  /* color: rgba(128, 128, 128, 0.281); */
  padding: 0.6rem;
  border-bottom: 1.5px solid #2d2d2d13;
  /* border-bottom: 1px solid #2d2d2d; */
}

.book-now-field:hover {
  transition-duration: 0.5s;
  background-image: linear-gradient(white, #b8336d3f);
  border-bottom-color: #b8336c;
}
.book-now-field_hidden {
  transition-duration: 0.5s;
  color: rgba(189, 188, 188, 0.281) !important;
  border-color: rgba(189, 188, 188, 0.281);
  padding: 0.6rem;
}

.book-now-field_hidden,
.book-now-field {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  /* gap: 33rem; */
  width: 100%;
  margin: auto;
  height: 3rem;
  margin-right: 0;
  margin-left: auto;
}

.customWig_label {
  padding-right: 1rem;
  justify-content: flex-end;
}

.book-now-field_dets {
  display: grid;
  grid-template-columns: auto auto;
  gap: 27rem;
  width: 100%;
  margin: auto;
  /* height: 3rem; */
  margin-right: 0;
  margin-left: auto;
}

.book-now_time {
  display: flex;
  gap: 5px;
}

.book-now_time input,
.book-now_time select {
  justify-content: start;
  align-items: flex-start;
  width: 235px !important;
  margin-right: 0;
  margin-left: auto;
  font-size: 1.2rem;
  padding: 10px;
}

/* .book-now-field input,
.book-now_time select {
  justify-content: start;
  align-items: flex-start;
  width: 500px;
  margin-right: 0;
  margin-left: auto;
  font-size: 1.2rem;
  padding: 10px;
} */

.book-now-field label {
  font-size: 1.2rem;
  /* align-items: center; */
  display: flex;
}

.book-now-field_dets label {
  font-size: 1.2rem;
  width: 400px;
}

.book-now-field_hidden input,
select {
  justify-content: start;
  align-items: flex-start;
  left: 0;
  width: 500px;
  margin-right: 0;
  margin-left: auto;
  font-size: 1.2rem;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.book-now-field_hidden input {
  width: 476px;
  border-width: 2px;
  border-color: #2d2d2d1a;
}

.book-now-field input {
  width: 476px;
  padding: 10px;
  border-width: 2px;
  border-color: #2d2d2d;
}

.book-now-field h3 {
  width: 476px;
  height: 400px;
  padding: 10px;
  border-width: 2px;
  border-color: #2d2d2d;
}

.book-now-field_dets h3 {
  width: 476px;
  /* height: 400px; */
  padding: 10px;
  border-width: 2px;
  border-color: #2d2d2d;
}

.book-now-field_hidden select {
  border-width: 2px;
  border-color: #2d2d2d1a;
}

.book-now-field select {
  border-width: 2px;
  border-color: #2d2d2d;
  overflow: hidden;
  background: url("/public/icons/arrow.svg") no-repeat right #fff;
  background-size: 15px;
  background-position: 97%;
}

.book-now-field span {
  width: 500px;
  margin-right: 0;
  margin-left: auto;
  font-size: 1.2rem;
  position: relative;
}

.book-now-field_hidden span {
  width: 500px;
  margin-right: 0;
  margin-left: auto;
  font-size: 1.2rem;
  position: relative;
  color: rgba(189, 188, 188, 0.281);
}

.book-now-field_hidden button {
  font-size: 1.2rem;
  position: relative;
  color: rgba(189, 188, 188, 0.281);
}
.book-now-field_hidden button {
  pointer-events: none;
}
#book-now-fieldDesc {
  height: 5rem;
}

.book-now-submitBtn {
  width: 200px;
  background-color: #b8336c;
  color: #fcfcfc;
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  padding: 1rem 1.5rem;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 50px;
}

.book-now-submitBtn:after {
  width: 90%;
  height: 90%;
  background-color: transparent;
  border: 1px solid #d62874;
  content: "";
  transition: all 0.2s;
  z-index: -1;
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 10px;
}

.book-now-submitBtn:hover::after {
  width: 100%;
  height: 100%;
  top: 4px;
  right: -6px;
}

.book-now-submitBtn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.booking-address-fields {
  display: grid;
  grid-template-columns: auto;
  /* width: 71%; */
  margin-left: auto;
}

.booking-address-fields input {
  font-size: 1.2rem;
  height: 2rem;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  border-width: 2px;
  margin-bottom: 1rem;
  border-color: #000000;
  /* color: #e02777; */
}

.booking-address-fields select {
  width: 100% !important;
  border-width: 2px;
  border-color: #2d2d2d;
  overflow: hidden;
  background: url("/public/icons/arrow.svg") no-repeat right #fff;
  background-size: 15px;
  background-position: 97%;
}

.booking-address-fields select,
input {
  width: 78%;
  margin-right: 0;
  margin-left: auto;
  font-size: 1.2rem;
  padding: 10px;
  appearance: none;
}

.booking-address-fields label {
  /* margin-right: 0; */
  /* margin-left: auto; */
  padding-top: 10px;
  appearance: none;
  /* width: 510px; */
}

.braided-extension_note {
  color: #e02777;
}

.book-now-field_note {
  color: #e02777;
  font-size: small;
}
.book-now-field_note a {
  color: #ad1e5c;
}
.book-now-field_note a:hover {
  text-decoration: underline;
}

.book-now-field_noteHidden {
  color: rgba(172, 105, 155, 0.281);
  border-color: rgba(189, 188, 188, 0.281);
  font-size: small;
}

.react-calendar__navigation {
  display: flex;
  padding-bottom: 2rem;
}

.react-calendar__tile_hidden {
  color: rgba(189, 188, 188, 0.281) !important;
  background-color: #2d2d2d00;
  border-color: #e0277715;
  border: none;
  border-radius: 1rem;
  font-family: MyFont, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  padding: 10px;
}

.react-calendar__tile,
.react-calendar__tile_hidden {
  /* color: rgba(189, 188, 188, 0.281); */
  background-color: #2d2d2d00;
  border-color: #e0277715;
  border: none;
  border-radius: 1rem;
  font-family: MyFont, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  padding: 10px;
}

.react-calendar__tile:hover {
  color: #e02777;
  background-color: #e027776b;
  transition: ease-in 0.1s;
}

.react-calendar__tile_hidden:hover {
  background-color: white !important;
  transition: ease-in 0.1s;
  pointer-events: none;
}

.react-calendar__tile--active {
  color: #e02777;
  background-color: #e027776b;
  transition: ease-in 0.1s;
}

.booking-calender_tile abbr {
  color: rgba(189, 188, 188, 0.281) !important;
}
.react-calendar__navigation__label {
  background-color: rgba(255, 255, 255, 0);
  border: none;
}

.react-calendar__navigation__arrow {
  width: 10%;
  height: 40px;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  font-size: larger;
}

.react-calendar__navigation__arrow:hover {
  color: #e02777;
  background-color: #e027776b;
  border: #e02777;
}

abbr[title] {
  text-decoration: none;
  padding: 1rem;
}

@media (max-width: 800px) {
  .book-now-field input[type="date"]:before,
  .book-now-field input[type="time"]:before {
    content: attr(placeholder) !important;
    color: black;
    /* width: -webkit-fill-available; */
    text-align: center;
    /* margin-right: 0.5em; */
  }
  .book-now-field input[type="time"]::content {
    content: attr(placeholder) !important;
    color: black;
    width: auto;
    /* margin-right: 0.5em; */
  }
  .book-now-field input[type="date"]:focus:before,
  .book-now-field input[type="date"]:valid:before,
  .book-now-field input[type="time"]:focus:before,
  .book-now-field input[type="time"]:valid:before {
    content: "";
  }
  .book-now-field_hidden,
  .book-now-field {
    grid-template-columns: 1fr !important;
    padding: 1rem;
    height: 100%;
    margin-right: auto;
    margin-left: 0;
  }
  .book-now_time {
    display: grid;
    grid-template-columns: 0.75fr 1fr !important;
    /* flex-direction: column; */
    gap: 1rem;
    width: 95%;
  }
  /* .book-now_time-ZONE {
    width: 350px !important;
    margin-left: 0;
  } */
  .book-now_time input,
  .book-now_time select {
    width: 100% !important;
    margin-left: 0;
    font-size: 1rem;
  }
  .book-now-field input,
  .book-now-field_hidden input {
    width: 88% !important;
    margin-left: 0;
  }
  #main-viewport {
    width: -webkit-fill-available;
    padding-right: 5%;
  }

  .react-calendar {
    width: -webkit-fill-available;
    margin-right: 10%;
  }
  .book-now-field label {
    padding-bottom: 10px;
  }
  .form-wrap {
    gap: 1rem;
  }
  .book-now-field_hidden input,
  select {
    width: 95%;
    margin-left: 0;
  }
  .book-now-field:hover {
    transition-duration: 0.5s;
    background-image: none;
    border-bottom-color: #b8336c;
  }
  .react-calendar__tile,
  .react-calendar__tile_hidden {
    font-size: 1rem;
    padding: 5px;
  }
  .form-wrap {
    padding: 2rem;
    width: 98%;
  }
  .book-now-field span {
    width: 100%;
  }
  .booking-address-fields {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    margin-left: auto;
  }
  .book-now-field_note-input {
    height: 100% !important;
  }
  .book-now-field_note a {
    text-decoration: underline;
  }
}
