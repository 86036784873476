.hero-wrap {
  margin-left: 21rem;
  margin-right: 21rem;
}
.hero-wrap figure {
  width: 750px;
  height: 400px;
  border: 1px solid #562361;
  box-shadow: -5px 5px 0 #562361;
}

.hero-wrap figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hero-wrap figure figcaption {
  font-size: 1.25rem;
  padding: 1.5rem;
  width: 370px;
}

.hero-wrap figure figcaption {
  top: 15rem;
  right: 250px;
}

.hero-wrap figure figcaption {
  background-color: #fcfcfc;
  border: 1px solid #b8336c;
  box-shadow: 5px 5px 0 #b8336c;
  color: #b8336c;
  line-height: 1.33;
  position: absolute;
}

.hero-wrap figure a {
  float: right;
  font-weight: 600;
  letter-spacing: 0.05rem;
  position: relative;
}

figcaption a {
  color: currentColor;
  text-decoration: none;
}
